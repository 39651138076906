import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AuthGuard } from './services/auth/auth.guard'
import { LayoutAuthComponent } from './layouts/auth/auth.component'
import { SharedModule } from './shared.module'

import { MainComponent } from './layouts/main/main.component'
import { LayoutsModule } from './layouts/layouts.module'

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'cases',
        loadChildren: () => import('./pages/case/case.module').then(m => m.CaseModule),
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./pages/account/account.module').then(m => m.AccountModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./pages/dashboard/report.module').then(m => m.ReportModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'user-report',
        loadChildren: () =>
          import('./pages/user-report/user-report.module').then(m => m.UserReportModule),
      },
      {
        path: 'design-parameters',
        loadChildren: () =>
          import('./pages/design-parameters/design-parameters.module').then(m => m.DesignParametersModule),
      },
      {
        path: 'meditlink-account',
        loadChildren: () =>
          import('./pages/meditlink/meditlink.module').then(m => m.MeditlinkModule),
      },
      {
        path: 'finance',
        loadChildren: () => import('./pages/finance/finance.module').then(m => m.FinanceModule),
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./pages/faq/faq.module').then(m => m.FaqModule),
      },
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    LayoutsModule,
  ],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
