import { Component } from '@angular/core';
import { User } from '../../models/user.model';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { MenuService } from '../../services/menu';
import { filter } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../environments/environment';
import { SiteInfoService } from '../../services/site-info/site-info.service';
import { UtilService } from '../../services/util.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
  isCollapsed = false;
  
  isMobileView: boolean = false;
  leftMenuWidth: number = 0;
  isMenuCollapsed: boolean = false;
  logo: string = '';
  menuData: any[] = [];
  menuDataActivated: any[] = [];
  sessionUser: User | null = null;
  role: any;
  logoUrl = environment.logo
  appName = environment.customerSiteName
  siteName = 'Web Portal'
  menuName = environment.app

  favIcon: any = document.querySelector('#favIcon');
  favIconUrl = environment.favi

  constructor(
    private menuService: MenuService,
    private router: Router,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private siteInfoService: SiteInfoService, 
    private utilService: UtilService,
    private titleService:Title
  ){
    this.menuService.getMenuData().subscribe(menuData => {
      this.sessionUser = this.userService.getCurrentUser();
      this.role = this.sessionUser.role;
      this.menuData = menuData;
      if (this.role == "clinic") {
        this.menuData = this.menuData.filter(
          menu =>
            menu.key != "accounts"
        );
      }
    });
    this.titleService.setTitle(this.appName + ' | ' + this.siteName)

    this.favIcon.href = this.favIconUrl;
  }

  ngOnInit() {
    this.activateMenu(this.router.url);
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.activateMenu(event.url ? event.url : null);
        // this.titleService.setTitle(this.appName + '|' + this.siteName)
      });
      this.loadSiteInfo()
      
  }

  activateMenu(url: any, menuData = this.menuData) {
    menuData = JSON.parse(JSON.stringify(menuData));
    this.menuDataActivated = menuData.slice();

    let currentUrl = this.router.url;
    const paths = currentUrl.split("/");
    console.log("path ==", paths);
    if (paths && paths.length > 0) {
      const activeMenu = paths[1];
      this.menuDataActivated.forEach(menu => {
        menu.selected = false;
        if (menu.key == activeMenu) {
          menu.selected = true;
          this.titleService.setTitle(this.appName + ' | ' + this.siteName + ' | ' + menu.title)
        }
      });
    }

    //get current menu
  }

  selectedMenu(item: any) {
    this.router.navigate([item.url]);
    this.menuDataActivated.forEach(menu => {
      menu.selected = false;
      if (menu.key == item.key) {
        menu.selected = true;
      }

    });
  }

  gotoAccountMgnt() {
    this.router.navigate(["account/account-listing"]);
  }

  checkCollapse(){
    const isMobile = this.deviceService.isMobile()
    if (isMobile){
      this.isCollapsed = !this.isCollapsed
    }
  }
  async loadSiteInfo() {
    try {
      let sig = await this.siteInfoService.getSiteInfoGeneral()
      this.appName = sig.name
    } catch (e) {
      this.utilService.showError(e)
    }
  }
}
