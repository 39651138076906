import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { environment } from '../../../environments/environment'
import { Title } from '@angular/platform-browser'
import { SiteInfoService } from '../../services/site-info/site-info.service'
import { UtilService } from '../../services/util.service'

@Component({
  selector: 'layout-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class LayoutAuthComponent {
  appName = environment.app
  version =  environment.appVersion
  currentParrent = ''
  siteName = ''

  favIcon: any = document.querySelector('#favIcon');
  favIconUrl: any = environment.favi

  constructor(private titleService:Title,
    private siteInfoService: SiteInfoService, 
    private utilService: UtilService,) {
      this.loadSiteInfo()

      this.favIcon.href = this.favIconUrl;
  }
 
  async loadSiteInfo() {
    try {
      let sig = await this.siteInfoService.getSiteInfoGeneral()
      this.siteName = sig.name
      this.titleService.setTitle(this.siteName != ''?this.siteName : this.appName)
    } catch (e) {
      this.utilService.showError(e)
    }
  }
}
