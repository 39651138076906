import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideNzIcons } from './icons-provider';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MockHttpCallInterceptor } from './services/fakeApi';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { environment } from '../environments/environment';
import { PayPalScriptService } from 'ngx-paypal';

registerLocaleData(en);

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideNzIcons(), provideNzI18n(en_US), PayPalScriptService,
    importProvidersFrom(FormsModule), importProvidersFrom(HttpClientModule), provideAnimations(),
     // fake http interceptors
     {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpCallInterceptor,
      multi: true,
    },

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'SERVICE_URL', useValue: environment.schema + '://' + environment.ezlab_url },
    {
      provide: 'WEBSOCKET_URL',
      useValue: environment.websocketSchema + '://' + environment.ezlab_url,
    },
  ]
};
