export const getMenuData: any[] = [
  {
    title: 'Dashboard',
    key: 'home',
    icon: 'bar-chart',
    url: '/home',
  },
  {
    title: 'Cases',
    key: 'cases',
    icon: 'profile',
    url: '/cases',
  },
  {
    title: 'Finances',
    key: 'finance',
    icon: 'snippets',
    url: '/finance',
  },
  {
    title: 'Customer Reports',
    key: 'user-report',
    icon: 'line-chart',
    url: '/user-report',
  },
  {
    title: 'Team',
    key: 'accounts',
    icon: 'team',
    url: '/accounts',
  },
  {
    title: 'Design Parameters',
    key: 'design-parameters',
    icon: 'setting',
    url: '/design-parameters',
  },
  // {
  //   title: 'Meditlink Account',
  //   key: 'meditlink-account',
  //   icon: 'link',
  //   url: '/meditlink-account',
  // },
 
]